<template>
  <section id="features">
    <div class="py-5"></div>

    <v-container class="text-center">
      <h2 class="display-2 font-weight-bold mb-3">{{$t('features.section.title')}}</h2>

      <v-responsive
        class="mx-auto mb-12"
        width="56"
      >
        <v-divider class="mb-1"></v-divider>

        <v-divider></v-divider>
      </v-responsive>

      <v-row>
        <v-col
          v-for="({ icon, title, text }, i) in appModules"
          :key="i"
          cols="6"
          md="3"
        >
          <v-card
            class="py-5 px-4"
            color="white"
            flat
          >
            <v-theme-provider light>
              <v-icon color="black" x-large>{{icon}}</v-icon>
              <!--v-img
                contain
                max-height="80px"
                :src="`/assets/app/static/companyTypes/${img}`"
              ></v-img-->
            </v-theme-provider>

            <v-card-title
              class="justify-center"
               v-text="$t(title.langKey)"
            ></v-card-title>

            <!--v-card-text
              class="subtitle-1"
              v-text="text"
            >
            </v-card-text-->
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <div class="text-center">
      <v-btn
        class="white--text align-self-end mt-3"
        color="purple"
        @click="$vuetify.goTo('#contact-form')"
      >
        {{$t('banner.buttons.register')}}
      </v-btn>
    </div>

    <div class="py-5"></div>
  </section>
</template>

<script>

export default {
  data () {
    return {
      appModules: [
        {
          title: { langKey: 'features.names.calendar' },
          id: 'calendar',
          icon: 'mdi-calendar-today',
          text: ''
        },
        {
          title: { langKey: 'features.names.onlineBooking' },
          id: 'online-booking',
          icon: 'mdi-cellphone',
          text: ''
        },
        {
          title: { langKey: 'features.names.inventories' },
          id: 'inventory',
          icon: 'mdi-cellphone-text',
          text: ''
        },
        {
          title: { langKey: 'features.names.loyalty' },
          id: 'loyalty',
          icon: 'mdi-hand',
          text: ''
        },
        {
          title: { langKey: 'features.names.pos' },
          id: 'billing-pos',
          icon: 'mdi-desktop-classic',
          text: ''
        },
        {
          title: { langKey: 'features.names.reports' },
          id: 'reports',
          icon: 'mdi-chart-line',
          text: ''
        },
        {
          title: { langKey: 'features.names.expenseControl' },
          id: 'control-expenses',
          icon: 'mdi-desktop-mac-dashboard',
          text: ''
        },
        {
          title: { langKey: 'features.names.manageMultipleLocations' },
          id: 'locations',
          icon: 'mdi-web',
          text: ''
        }
      ]
    }
  }
}
</script>
