import { render, staticRenderFns } from "./Contact.vue?vue&type=template&id=145f66ac&scoped=true&"
import script from "./Contact.vue?vue&type=script&lang=js&"
export * from "./Contact.vue?vue&type=script&lang=js&"
import style0 from "./Contact.vue?vue&type=style&index=0&id=145f66ac&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "145f66ac",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VResponsive } from 'vuetify/lib/components/VResponsive';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VThemeProvider } from 'vuetify/lib/components/VThemeProvider';
installComponents(component, {VAlert,VAutocomplete,VBtn,VCheckbox,VCol,VContainer,VDivider,VResponsive,VRow,VSheet,VSnackbar,VTextField,VTextarea,VThemeProvider})
