<template>
  <v-sheet
    id="contact"
    light
    tag="section"
    tile
  >
    <div class="py-5"></div>
    <v-container>
      <v-row>
        <v-col cols="12" md="6">
          <v-container>
            <h2 class="display-1 font-weight-bold mb-3 text-center">Lizto</h2>
            <v-responsive
              class="mx-auto mb-2"
              width="56"
            >
              <v-divider class="mb-1"></v-divider>

              <v-divider></v-divider>
            </v-responsive>

          <div class="text-center video-container">
            <iframe
              :src="urlLiztoVideo"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          </v-container>
        </v-col>
        <v-col cols="12" md="6">
          <v-container id="contact-form">
            <h2 class="display-1 font-weight-bold mb-3 text-center">{{$t('contact.section.title')}}</h2>
            <v-responsive
              class="mx-auto mb-2"
              width="56"
            >
              <v-divider class="mb-1"></v-divider>

              <v-divider></v-divider>
            </v-responsive>

            <v-theme-provider light>
              <v-row>
                  <v-col cols="12">
                    <v-text-field
                      :label="$t('contact.form.fields.name')"
                      outlined
                      flat
                      v-model="form.name"
                    ></v-text-field>

                    <v-text-field
                      :label="$t('contact.form.fields.email')"
                      outlined
                      flat
                      v-model="form.email"
                    ></v-text-field>
                    <v-row>
                      <v-col cols="4" sm="2" md="4" lg="4">
                        <!--:append-icon="appendIcon"-->
                        <v-autocomplete
                          outlined
                          flat
                          ref="countryInput"
                          v-model="form.cellphoneIndicator"
                          @change="onChangeCountryCode"
                          :items="listCountries"
                          item-text="text"
                          item-value="value"
                          return-object
                        >
                          <!--template v-slot:prepend>
                            <span
                              :class="`fi fi-${form.cellphoneIndicator.iso2}`"
                              style="width: 50px;height: 30px; background-size: contain; background-position: 50%; background-repeat: no-repeat;"
                            />
                          </template-->
                          <template v-slot:selection="data">
                            <span
                              :class="`fi fi-${form.cellphoneIndicator.iso2}`"
                              style="width: 50px;height: 30px; background-size: contain; background-position: 50%; background-repeat: no-repeat;"
                            />
                            <!--span>{{ data.item.text }}</span-->
                          </template>
                          <template v-slot:item="data">
                            <span
                              :class="`fi fi-${data.item.iso2}`"
                              style="width: 50px;height: 30px; background-size: contain; background-position: 50%; background-repeat: no-repeat;"
                            />
                            <span>{{ data.item.text }}</span>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="8" sm="10" md="8" lg="8">
                        <v-text-field
                          :label="$t('contact.form.fields.cellphone')"
                          outlined
                          flat
                          v-model="form.phone"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-textarea
                      :label="$t('contact.form.fields.message')"
                      outlined
                      flat
                      rows="3"
                      v-model="form.message"
                    ></v-textarea>

                    <v-checkbox light color="purple" v-model="form.accept_terms_and_conditions">
                      <template v-slot:label>
                        <p>
                          {{$t('contact.form.fields.acceptTermsAndConditions')}}
                        </p>
                      </template>
                    </v-checkbox>

                    <a target="blank" class="text-left my-1 d-block" :href="getLinkPolicyProcessingPersonalData()">
                      {{$t('contact.form.fields.policyProcessingPersonalData')}}
                    </a>

                    <br>

                    <v-btn
                      color="accent"
                      block
                      @click="sendMail"
                    >
                      {{$t('contact.form.fields.send')}}
                    </v-btn>

                    <br>

                    <p class="m-0">
                      Este sitio está protegido por reCAPTCHA y se aplican la
                      <a href="https://policies.google.com/privacy">Política de privacidad</a> y los
                      <a href="https://policies.google.com/terms">Términos de servicio</a> de Google.
                    </p>
                  </v-col>

                  <v-col cols="12" v-if="sending">
                    <v-alert dark type="info">
                      {{$t('common.text.sendingMessage')}}
                    </v-alert>
                  </v-col>
              </v-row>
            </v-theme-provider>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
    <div class="py-5"></div>

    <v-snackbar
      :color="snackbar.color"
      v-model="snackbar.show"
    >
      {{ snackbar.text }}
    </v-snackbar>
  </v-sheet>
</template>

<script>
import ApiService from '@/common/api.service'
import reCAPTCHAConfig from '@/config/reCAPTCHA.json'
import countries from '@/common/countries.js'

export default {
  data () {
    return {
      urlLiztoVideo: 'https://www.youtube.com/embed/b-47v3FMLEw',
      listCountries: [],
      snackbar: {
        show: false,
        text: '',
        color: 'green'
      },
      sending: false,
      form: {
        name: '',
        email: '',
        phone: '',
        cellphoneIndicator: null,
        message: '',
        token: '',
        accept_terms_and_conditions: false,
        cellphone_indicator: null
      }
    }
  },
  methods: {
    onChangeCountryCode () {

    },
    getCountries () {
      this.listCountries = countries
        .map(country => {
          const i18nTextCountryName = this.$t(`countries.${country.iso2}`)
          return {
            text: `(+${country.dialCode}) ${i18nTextCountryName}`,
            value: country.dialCode,
            iso2: country.iso2
          }
        })
    },
    getLinkPolicyProcessingPersonalData () {
      if (this.$root.$i18n.locale === 'es') {
        return 'https://app.lizto.co/policy-processing-personal-data'
      } else if (this.$root.$i18n.locale === 'en') {
        return 'https://app.lizto.co/policy-processing-personal-data/en'
      } else {
        return 'https://app.lizto.co/policy-processing-personal-data'
      }
    },
    getRecaptchaToken () {
      // eslint-disable-next-line no-undef
      return grecaptcha.execute(reCAPTCHAConfig.key, { action: 'contact' })
        .then((token) => { this.form.token = token })
    },
    initRecaptcha () {
      // eslint-disable-next-line no-undef
      // grecaptcha.ready(() => {
      //   // this.getRecaptchaToken()
      // })
    },
    showMessage () { this.snackbar.show = true },
    hideMessage () { this.snackbar.show = false },
    getData () {
      const data = {
        name: this.form.name,
        email: this.form.email,
        phone: this.form.phone,
        token: this.form.token,
        message: this.form.message,
        accept_terms_and_conditions: this.form.accept_terms_and_conditions,
        cellphone_indicator: ''
      }
      if (this.form.cellphoneIndicator) {
        data.cellphone_indicator = this.form.cellphoneIndicator.value
      }

      return data
    },
    reset () {
      this.form.name = ''
      this.form.email = ''
      this.form.phone = ''
      this.form.message = ''
      this.form.accept_terms_and_conditions = false
      this.form.cellphoneIndicator = null

      // this.getRecaptchaToken()
    },
    sendMail () {
      try {
        this.getRecaptchaToken()
          .then(() => {
            const data = this.getData()

            this.sending = true
            ApiService.sendMail(data)
              .then(res => {
                this.sending = false
                if (res.ok) {
                  this.snackbar.color = 'green'
                } else {
                  this.snackbar.color = 'blue'
                }

                this.snackbar.text = res.message

                this.showMessage()

                if (res.ok) {
                  window.location.assign('/gracias')
                  this.reset()
                }
              })
              .catch(() => {
                this.sending = false

                this.snackbar.text = this.$t('common.text.errorHasOccurred')
                this.snackbar.color = 'red'
                this.showMessage()
              })
          })
      } catch (err) {
        this.snackbar.text = 'No pudimos procesar tu mensaje en este momento (Recaptcha)'
        this.snackbar.color = 'red'
        this.showMessage()
      }
    }
  },
  mounted () {
    const recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('src', `https://www.google.com/recaptcha/api.js?render=${reCAPTCHAConfig.key}`)
    document.head.appendChild(recaptchaScript)

    this.getCountries()

    setTimeout(() => {
      this.initRecaptcha()
    }, 3000)
  }
}
</script>

<style scoped>
.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
