var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"white",attrs:{"id":"customers"}},[_c('div',{staticClass:"py-5"}),_c('v-container',{staticClass:"text-center"},[_c('h2',{staticClass:"display-2 font-weight-bold mb-3"},[_vm._v(_vm._s(_vm.$t('customers.section.title')))]),_c('v-responsive',{staticClass:"mx-auto mb-12",attrs:{"width":"56"}},[_c('v-divider',{staticClass:"mb-1"}),_c('v-divider')],1),_c('v-tabs',{attrs:{"show-arrows":""},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},_vm._l((_vm.companyTypes),function(ref,i){
var i18nPath = ref.i18nPath;
var id = ref.id;
return _c('v-tab',{key:i,attrs:{"href":("#" + id)}},[_vm._v(" "+_vm._s(_vm.$t(i18nPath))+" ")])}),1),_c('v-row',[_c('v-sheet',{staticClass:"mx-auto",attrs:{"elevation":"8","max-width":"100%"}},[_c('v-slide-group',{staticClass:"pa-4",attrs:{"active-class":"success","show-arrows":""}},_vm._l((_vm.getCustomersByActiveTab()),function(ref,i){
var img = ref.img;
var title = ref.title;
var text = ref.text;
return _c('v-slide-item',{key:i,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-card',{staticClass:"py-5 px-4",attrs:{"color":"white","flat":""}},[_c('v-theme-provider',{attrs:{"dark":""}},[_c('v-img',{attrs:{"contain":"","max-height":"100%","height":"200px","width":"200","src":("/assets/app/static/customers/" + img)}})],1)],1)]}}],null,true)})}),1)],1)],1)],1),_c('div',{staticClass:"text-center"},[_c('v-btn',{staticClass:"white--text align-self-end mt-3",attrs:{"color":"purple"},on:{"click":function($event){return _vm.$vuetify.goTo('#contact-form')}}},[_vm._v(" "+_vm._s(_vm.$t('banner.buttons.register'))+" ")])],1),_c('div',{staticClass:"py-5"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }