<template>
  <section
    id="customers"
    class="white"
  >

    <div class="py-5"></div>

    <v-container class="text-center">
      <h2 class="display-2 font-weight-bold mb-3">{{$t('customers.section.title')}}</h2>

      <v-responsive
        class="mx-auto mb-12"
        width="56"
      >
        <v-divider class="mb-1"></v-divider>

        <v-divider></v-divider>
      </v-responsive>

      <v-tabs
        v-model="activeTab"
        show-arrows
      >
        <v-tab
          v-for="({ i18nPath, id }, i) in companyTypes"
          :key="i"
          :href="`#${id}`"
        >
          {{$t(i18nPath)}}
        </v-tab>
      </v-tabs>

      <v-row>
        <v-sheet
          class="mx-auto"
          elevation="8"
          max-width="100%"
        >
          <v-slide-group
            class="pa-4"
            active-class="success"
            show-arrows
          >
            <v-slide-item
              v-for="({ img, title, text }, i) in getCustomersByActiveTab()"
              :key="i"
              v-slot="{ active, toggle }"
            >
              <v-card
                class="py-5 px-4"
                color="white"
                flat
              >
                <v-theme-provider dark>
                  <v-img
                    contain
                    max-height="100%"
                    height="200px"
                    width="200"
                    :src="`/assets/app/static/customers/${img}`"
                  ></v-img>
                </v-theme-provider>

                <!--v-card-title
                  class="justify-center"
                  v-text="title"
                ></v-card-title-->

                <!--v-card-text
                  class="subtitle-1"
                  v-text="text"
                >
                </v-card-text-->
              </v-card>
            </v-slide-item>
          </v-slide-group>
        </v-sheet>

        <!--v-col
          v-for="({ img, title, text }, i) in getCustomersByActiveTab()"
          :key="i"
          cols="4"
          md="3"
        >
          <v-card
            class="py-5 px-4"
            color="white"
            flat
          >
            <v-theme-provider dark>
              <v-img
                contain
                max-height="100%"
                :src="`/assets/app/static/customers/${img}`"
              ></v-img>
            </v-theme-provider>

            --v-card-title
              class="justify-center"
              v-text="title"
            ></v-card-title--

            --v-card-text
              class="subtitle-1"
              v-text="text"
            >
            </v-card-text--
          </v-card>
        </v-col-->
      </v-row>
    </v-container>

    <div class="text-center">
      <v-btn
        class="white--text align-self-end mt-3"
        color="purple"
        @click="$vuetify.goTo('#contact-form')"
      >
        {{$t('banner.buttons.register')}}
      </v-btn>
    </div>

    <div class="py-5"></div>
  </section>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data () {
    return {
      model: null,
      activeTab: null,
      customers: [
        {
          title: 'agla',
          type: 'hairdressing',
          img: 'agla.jpeg'
        },
        {
          title: 'kasah-studio',
          type: 'hairdressing',
          img: 'kasah-studio.png'
        },
        // Luisa Hairs
        {
          title: 'martin-vidal',
          type: 'hairdressing',
          img: 'martin-vidal.jpeg'
        },
        {
          title: 'peluquerias-emiro',
          type: 'hairdressing',
          img: 'peluquerias-emiro.jpg'
        },
        {
          title: 'magno-salon',
          type: 'hairdressing',
          img: 'magno-salon.png'
        },
        {
          title: 'biubelleza',
          type: 'hairdressing',
          img: 'biubelleza.jpg'
        },
        {
          title: 'wilfredo-chacon',
          type: 'hairdressing',
          img: 'wilfredo-chacon.jpg'
        },
        {
          title: 'yeni-ramirez',
          type: 'hairdressing',
          img: 'yeni-ramirez.jpg'
        },
        {
          title: 'team-hear',
          type: 'hairdressing',
          img: 'team-hear.jpeg'
        },
        {
          title: 'trendy',
          type: 'hairdressing',
          img: 'trendy.jpg'
        },
        {
          title: 'murano',
          type: 'hairdressing',
          img: 'murano.jpg'
        },
        // Creative Staff
        {
          title: 'b-antra',
          type: 'hairdressing',
          img: 'b-antra.jpg'
        },
        {
          title: 'casa-studio',
          type: 'hairdressing',
          img: 'casa-studio.jpg'
        },
        {
          title: 'gioia',
          type: 'hairdressing',
          img: 'gioia.jpg'
        },
        {
          title: 'lv-peluqueria-estetica',
          type: 'hairdressing',
          img: 'lv-peluqueria-estetica.jpg'
        },

        // Hair Spa Salón

        {
          title: 'hairfit',
          type: 'hair_spa_salon',
          img: 'hairfit.jpg'
        },
        {
          title: 'carolina-aristizabal',
          type: 'hair_spa_salon',
          img: 'carolina-aristizabal.jpg'
        },
        {
          title: 'lorena-lissados',
          type: 'hair_spa_salon',
          img: 'lorena-lissados.jpg'
        },
        {
          title: 'blue-capilar',
          type: 'hair_spa_salon',
          img: 'blue-capilar.jpg'
        },
        // Super Alisados JM

        // Barberias
        {
          title: 'mustaches',
          type: 'barbershops',
          img: 'mustaches.jpg'
        },
        {
          title: 'figaro',
          type: 'barbershops',
          img: 'figaro.jpg'
        },
        {
          title: 'gentleman',
          type: 'barbershops',
          img: 'gentleman.jpg'
        },
        // Cornale Barbería

        // Spa de cejas y pestañas
        {
          title: 'purple-beauty',
          type: 'eyebrow_and_eyelash_spa',
          img: 'purple-beauty.jpg'
        },
        {
          title: 'lashesaa',
          type: 'eyebrow_and_eyelash_spa',
          img: 'lashesaa.jpg'
        },
        {
          title: 'life-artist',
          type: 'eyebrow_and_eyelash_spa',
          img: 'life-artist.jpg'
        },
        {
          title: 'miradas-que-impactan',
          type: 'eyebrow_and_eyelash_spa',
          img: 'miradas-que-impactan.jpg'
        },

        // Salón de uñas
        {
          title: 'imagen-belleza-vip',
          type: 'nail_salon',
          img: 'imagen-belleza-vip.jpeg'
        },
        {
          title: 'imagen-belleza',
          type: 'nail_salon',
          img: 'imagen-belleza.jpeg'
        },
        {
          title: 'leidy-laura-nails',
          type: 'nail_salon',
          img: 'leidy-laura-nails.jpeg'
        },
        {
          title: 'vintage-in-love',
          type: 'nail_salon',
          img: 'vintage-in-love.jpg'
        }
        // Princess Nails
        // Queen House
      ]
    }
  },
  methods: {
    getCustomersByActiveTab () {
      return this.customers.filter(customer => {
        if (typeof customer.type === 'string') {
          return customer.type === this.activeTab
        }
        if (Array.isArray(customer.type)) {
          return customer.type.indexOf(this.activeTab) > -1
        }
        return false
      })
    }
  },
  computed: {
    ...mapState({
      companyTypes: state => state.webPage.companyTypes
    })
  }
}
</script>
