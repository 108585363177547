<template>
  <section id="banner">
    <v-row no-gutters>
      <v-carousel
        cycle
        height="450"
        reverse-transition="fade-transition"
        transition="fade-transition"
        hide-delimiter-background
        :show-arrows="false"
        hide-delimiters
        show-arrows-on-hover
      >
        <v-carousel-item
          v-for="(slide, i) in slides"
          :key="i"
          :src="`/assets/app/static/slide/${slide.img}`"
        >
          <v-sheet
            height="100%"
            tile
            style="background: rgba(0,0,0,0.4);"
          >
            <div
              class="white--text mx-auto d-flex fill-height align-start justify-center"
              height="400"
            >
              <div
                class="white--text text-center mt-12"
              >
                <span
                  :class="[$vuetify.breakpoint.smAndDown ? 'display-2': 'display-3']"
                  class="font-weight-black"
                >
                  {{$t('banner.text1')}}
                </span>

                <br>

                <span
                  class="font-weight-light"
                  :class="[$vuetify.breakpoint.smAndDown ? 'display-1' : 'display-2']"
                >
                  {{$t('banner.text2')}}
                </span>

                <br>

                <v-btn
                  class="white--text align-self-end mt-3"
                  color="purple"
                  @click="$vuetify.goTo('#contact-form')"
                >
                  {{$t('banner.buttons.register')}}
                </v-btn>
              </div>

            </div>
          </v-sheet>
          <v-theme-provider dark >
          </v-theme-provider>
        </v-carousel-item>
      </v-carousel>
    </v-row>
  </section>
</template>

<script>
export default {
  data () {
    return {
      slides: [
        { img: 'slide-1.jpg' },
        { img: 'slide-2.jpg' },
        { img: 'slide-3.jpg' }
      ]
    }
  },
  methods: {
  }
}
</script>
