<template>
  <section
    id="companyTypes"
    class="grey lighten-3"
  >
    <div class="py-5"></div>

    <v-container class="text-center">
      <h2 class="display-2 font-weight-bold mb-3">{{$t('companyTypes.section.title')}}</h2>

      <v-responsive
        class="mx-auto mb-12"
        width="56"
      >
        <v-divider class="mb-1"></v-divider>

        <v-divider></v-divider>
      </v-responsive>

      <v-row>
        <v-col
          v-for="({ img, i18nPath, text }, i) in companyTypes"
          :key="i"
          cols="6"
          md="3"
        >
          <v-card
            class="py-5 px-4"
            color="grey lighten-3"
            flat
          >
            <v-theme-provider dark>
              <v-img
                contain
                max-height="80px"
                :src="`/assets/app/static/companyTypes/${img}`"
              ></v-img>
            </v-theme-provider>

            <v-card-title
              class="justify-center"
              v-text="$t(i18nPath)"
            ></v-card-title>

            <!--v-card-text
              class="subtitle-1"
              v-text="text"
            >
            </v-card-text-->
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <div class="text-center">
      <v-btn
        class="white--text align-self-end mt-3"
        color="purple"
        @click="$vuetify.goTo('#contact-form')"
      >
        {{$t('banner.buttons.register')}}
      </v-btn>
    </div>

    <div class="py-5"></div>
  </section>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data () {
    return {
    }
  },
  methods: {
  },
  computed: {
    ...mapState({
      companyTypes: state => state.webPage.companyTypes
    })
  }
}
</script>
