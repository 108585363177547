<template>
  <v-content>
    <Banner/>
    <Features/>
    <CompanyTypes/>
    <Customers/>
    <!--Pricing/-->
    <Contact/>
  </v-content>
</template>

<script>

import Banner from '@/views/components/Banner.vue'
import CompanyTypes from '@/views/components/CompanyTypes.vue'
// import Pricing from '@/views/components/Pricing.vue'
import Contact from '@/views/components/Contact.vue'
import Customers from '@/views/components/Customers.vue'
import Features from '@/views/components/Features.vue'

export default {
  data () {
    return {
    }
  },
  methods: {
    setDocumentTitle () {
      document.title = this.$t('base.documentTitle')
    }
  },
  components: {
    Banner,
    CompanyTypes,
    // Pricing,
    Contact,
    Customers,
    Features
  },
  watch: {
    '$root.$i18n.locale' () { this.setDocumentTitle() }
  },
  mounted () {
    let defLocale = 'es'

    if (this.$route.query.l) {
      if (['es', 'en'].indexOf(this.$route.query.l) >= 0) {
        defLocale = this.$route.query.l
      }
    }

    this.$root.$i18n.locale = defLocale

    this.$nextTick(() => {
      if (this.$route.hash) {
        this.$vuetify.goTo(this.$route.hash)
      }
    })
  },
  created () { this.setDocumentTitle() }
}
</script>
